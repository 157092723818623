import React, { ReactNode, useEffect } from "react"
import { ImageDataLike } from "gatsby-plugin-image"

import Button from "@material-ui/core/Button"
import Container from "@ecom/ui/components/Container"
import { Img } from "./Img"

import * as stylesOrig from "./banner.module.scss"
import { handleClickBanner } from "../../../../helpers/WatcherDL/utils/handleClickBanner"

export type BannerProps = {
  large: ImageDataLike
  mob: ImageDataLike
  title: string
  description: string
  children?: ReactNode
  withButton?: Boolean
  buttonText?: string
  styles?: any
  orderNum?: string
}

export const Banner = ({
  large,
  mob,
  title,
  description,
  children,
  withButton = true,
  buttonText = "Заполнить заявку",
  styles = stylesOrig,
  orderNum,
}: BannerProps) => {
  useEffect(() => {
    document.body.style.display = "block"
  }, [])

  return (
    <section className={styles.section} id="dynamic-banner" data-exclude={orderNum}>
      <Img className={styles.img} alt="bg image" large={large} mob={mob} />
      <Container className={styles.container}>
        <div className={styles.text}>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        {children}
        {withButton && (
          <Button
            className={styles.btn}
            color="secondary"
            onClick={() => handleClickBanner("click_cta")}
          >
            {buttonText}
          </Button>
        )}
      </Container>
    </section>
  )
}
